import React, { Component } from 'react'
import Helmet from 'react-helmet'

import Layout from './../components/Layout'
import IMGInternetSharing from './../assets/images/mhotspot--laptop-wifi-router.jpg'
import IMGWiFiRepeater from './../assets/images/extend-wifi-range-mhotspot.jpg'

export class uses extends Component {
  render() {
    return (
      <Layout>
        <Helmet>
          <title>Uses of mHotspot</title>
        </Helmet>
        <h2 style={{ fontSize: 26 }}>Uses of mHotspot</h2>
        <br />
        <div>
          <h2 style={{ fontSize: 22 }}>1. Internet Sharing</h2>
          <img src={IMGInternetSharing} alt="mhotspot internet sharing"/>
          <br />
          <br />
          <div>
            Firstly, the main use of mHotspot is to share a single internet
            connection for multiple devices. For instance, you are using
            ethernet or 3g data card for your laptop. If you want to use the
            same internet connection for your Wifi enabled mobile phone,
            download mhotspot and start the wifi hotspot.By searching for the
            newly ceated Wifi network, the device can connect to wifi and enjoy
            the internet of laptop.The devices might include
            laptops,iphones,android phones, other smartphones, tablet-pcs, PDAs,
            Wii, XboX or any other wifi device. Fully customizable Hotspot Name
          </div>
        </div>
        <br />
        <br />
        <div>
          <h2 style={{ fontSize: 22 }}>2. Extend Wi-Fi Range</h2>
          <img src={IMGWiFiRepeater} alt="mhotspot use as a repeater"/>
          <br />
          <br />
          <div>
            A laptop with mHotspot can act as a repeater.i.e., a laptop can
            increase the range of a wifi network by generating an additional
            wifi network as shown in the figure below.
          </div>
        </div>
        <br />
        <br />
        <div>
          <h2 style={{ fontSize: 22 }}>3. Multiplayer Gaming</h2>
          <div>
            Yes, you can play multiplayer game without the hassle of LAN cables
            or Ethernet-HUBS. HOW?
            <br /> <br />
            1. Download mHotspot on any laptop and setup the hotspot with 'No
            Internet Sharing'.
            <br /> 2. Give the password to your friends, team so that they
            connect to the hotspot
            <br />
            3. Voila! You successfully created your own wireless network without
            LAN Cables or Wifi Router!!
          </div>
        </div>
        <br />
        <br />
        <div>
          <h2 style={{ fontSize: 22 }}>4. Connect mobile to laptop</h2>
          <div>
            Here is an interesting task that be done with mHotspot. Create a
            hotspot and connect your android phone to the hotspot.
            <br />
            Now install <a href="https://airdroid.com">AirDroid</a> on your
            mobile and start the application. It will generate a url and
            password. Enter that details in any web browser.
            <br />
            Thats it! You can manage your phones' messages, contacts, pictures,
            videos, files, applications from the web browser in your laptop.
            Isn't that nice?
          </div>
        </div>
      </Layout>
    )
  }
}

export default uses
